import React, { FC, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { createMarkup, isBrowser } from "@zempler/utils";
import { ThemeColorsKeys } from "../../styles/tokens/colors";
import { Close } from "../icon";
import Typography from "../typography/Typography";
import Container from "../container/Container";
import { useBackgroundColor } from "../../utils";
import { IconMap } from "../icon/cmsIcons";
import { useDataLayerPush } from "../../hooks/useDataLayerPush";

// Interface

export interface SitewideBannerProps {
  enabled: boolean;
  heading?: string;
  description?: string;
  icon?: keyof typeof IconMap;
  backgroundColour: ThemeColorsKeys;
  bannerId: string;
  disableSiteWideBanner?: boolean;
}

interface StyledSitewideBannerProps {
  backgroundColour: ThemeColorsKeys;
}

// Styles

const StyledSitewideBanner = styled.div<StyledSitewideBannerProps>`
  ${({ theme: { space, colors, media }, backgroundColour }) => css`
    ${useBackgroundColor(backgroundColour)}
    padding-top: ${space.xSmall};
    padding-bottom: ${space.xSmall};
  `};
`;

const StyledHeader = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.xxxSmall};
  `};
`;

const StyledBody = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xxxSmall};

    @media (min-width: ${media.medium}) {
      flex-direction: row;
      gap: ${space.xSmall};
    }
  `};
`;

const StyledContainer = styled(Container)`
  ${({ theme: { space, media } }) => css`
    display: flex;
    justify-content: space-between;
    gap: ${space.small};

    @media (min-width: ${media.medium}) {
      align-items: center;
    }
  `};
`;

// JSX

export const SitewideBanner: FC<
  SitewideBannerProps & { onDismissChanged?: () => void }
> = ({
  enabled,
  heading,
  description,
  icon,
  backgroundColour,
  bannerId,
  onDismissChanged,
}) => {
  const [dismissed, setDismissed] = useState(true);
  const { dataLayerPush } = useDataLayerPush();

  useEffect(() => {
    if (isBrowser) {
      const dismissedBannerId = localStorage.getItem("dismissedBannerId");
      if (dismissedBannerId !== bannerId) {
        setDismissed(false);
      }
    }
  }, []);

  const handleDismiss = () => {
    if (isBrowser) {
      localStorage.setItem("dismissedBannerId", bannerId);
      setDismissed(true);

      dataLayerPush({
        event: "dismiss_sitewide_banner",
        bannerId,
      });
    }
  };

  // USe effect to check if dismissed is true or not

  useEffect(() => {
    if (onDismissChanged) {
      onDismissChanged();
    }
  }, [dismissed]);

  if (!enabled || dismissed) {
    return null;
  }

  // Handle icon

  let Icon = null;

  // Check that all icons are defined
  Object.entries(IconMap).forEach(([key, value]) => {
    if (!value) {
      console.error(`Icon with key ${key} is undefined.`);
    }
  });

  if (icon) {
    const normalizedIcon = icon.replace(/\s+/g, "");
    const dynamicIconKey =
      normalizedIcon.charAt(0).toUpperCase() + normalizedIcon.slice(1);
    Icon = IconMap[dynamicIconKey as keyof typeof IconMap];
  }

  return (
    <>
      {enabled && !dismissed && (
        <StyledSitewideBanner
          backgroundColour={backgroundColour || "zemplerBlue"}
        >
          <StyledContainer width="extraWide">
            <StyledBody>
              <StyledHeader>
                {Icon && <Icon />}
                <Typography component="p" variant="bodySmallBoldToBodyBold">
                  {heading}
                </Typography>
              </StyledHeader>
              {description && (
                <Typography component="div" variant="bodySmallToBody">
                  {createMarkup(description)}
                </Typography>
              )}
            </StyledBody>

            <button
              type="button"
              onClick={() => handleDismiss()}
              title="Close notification"
            >
              <Close />
            </button>
          </StyledContainer>
        </StyledSitewideBanner>
      )}
    </>
  );
};

export default SitewideBanner;
